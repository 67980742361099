/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
	AdminUserResource,
	AdvanceSearchable,
	AdvanceSearchRequest,
	ApiResponseTemplate,
	BannerResource,
	BscoreBscoreAdminUserInitAccountCreateRequestPayload,
	BscoreBscoreAdminUserUnlockAccountCreateRequestPayload,
	BscoreDestroyAllCreateRequestPayload,
	BscoreExcelImportExampleFileCreateRequestPayload,
	BscoreExcelImportUploadUrlCreateRequestPayload,
	BscoreExportResult,
	BscoreForgetPasswordCreateRequestPayload,
	BscoreGetForgetMailInfoListRequestParams,
	BscoreGetUnlockMailInfoListRequestParams,
	BscoreGetVerifyMailInfoListRequestParams,
	BscoreImportResult,
	BscoreLoginCreateRequestPayload,
	BscoreResetPasswordCreateRequestPayload,
	BscoreResetPasswordFromMailCreateRequestPayload,
	BscoreSettingsUpdateRequestPayload,
	BscoreSubListDestroyAllCreateRequestPayload,
	BscoreSubListExcelImportExampleFileCreateRequestPayload,
	BscoreSubListExcelImportUploadUrlCreateRequestPayload,
	BscoreSubListUpdateListCreateRequestParams,
	BscoreSubListUpdateListCreateRequestPayload,
	BscoreSubListUpdateRowCreateRequestPayload,
	BscoreUnlockAccountCreateRequestPayload,
	BscoreUpdateListCreateRequestParams,
	BscoreUpdateListCreateRequestPayload,
	BscoreUpdateRowCreateRequestPayload,
	BscoreVerifyAccountCreateRequestPayload,
	CountryIndexResource,
	EventIndexResource,
	EventShowResource,
	ExcelSearchRequest,
	FormContactResource,
	FormEventResource,
	FormRegisterResource,
	GetDataCreate,
	GetDataUpdate,
	GetListDataPaginator,
	HomePageCollection,
	ImportRequest,
	MenuItemResource,
	MenuResource,
	PraiseResource,
	PromotionalOfferResource,
	SearchRequest,
	TagIndexResource,
	TagTourStyleCollection,
	TourGroupIndexResource,
	TourGroupResource,
	TourIndexResource,
	TourResource,
	TravelColumnResource,
	TravelTipShowResource,
	UserResource,
	V1AuthLoginCreateRequestPayload,
	V1AuthUserCreateRequestPayload,
	V1AuthUserEmailCheckCreateRequestPayload,
	V1AuthUserResetPasswordCreateRequestPayload,
	V1EventsListRequestParams,
	V1FormSubmitContactCreateRequestPayload,
	V1FormSubmitEventCreateRequestPayload,
	V1FormSubmitRegisterCreateRequestPayload,
	V1PromotionalOffersListRequestParams,
	V1SendVerifyCodeCreateRequestPayload,
	V1TourGroupsListRequestParams,
	V1ToursListRequestParams,
	V1TravelColumnsListRequestParams,
	V1UserEmailUpdateRequestPayload,
	V1UserFormDetailRequestParams,
	V1UserUpdateRequestPayload,
	V1VerifyCodeCreateRequestPayload,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Api<SecurityDataType = unknown> {
	http: HttpClient<SecurityDataType>;

	constructor(http: HttpClient<SecurityDataType>) {
		this.http = http;
	}

	/**
	 * @description 取得所有上線國家
	 *
	 * @tags country
	 * @name V1CountryList
	 * @summary 取得所有上線國家
	 * @request GET:/api/v1/country
	 */
	v1CountryList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: CountryIndexResource[];
				};
			},
			any
		>({
			path: `/api/v1/country`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 活動列表資訊
	 *
	 * @tags event
	 * @name V1EventsList
	 * @summary 活動列表
	 * @request GET:/api/v1/events
	 */
	v1EventsList = (query: V1EventsListRequestParams, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: EventIndexResource[];
					/** 分頁連結 */
					link?: {
						/** 第一頁 */
						first?: string;
						/** 最後一頁 */
						last?: string;
						/** 上一頁 */
						prev?: string;
						/** 下一頁 */
						next?: string;
					};
					/** 分頁資訊 */
					meta?: {
						/** 目前回傳資料的頁數 */
						current_page?: number;
						/** 回傳資料從第幾筆開始 */
						from?: number;
						/** 可取得資料的最後一頁頁數 */
						last_page?: number;
						/** 當前 path */
						path?: string;
						/** 每頁幾筆 */
						per_page?: number;
						/** 回傳資料的最後一筆是第幾頁 */
						to?: number;
						/** 總頁數 */
						total?: number;
					};
				};
			},
			any
		>({
			path: `/api/v1/events`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 活動內頁詳細資訊
	 *
	 * @tags event
	 * @name V1EventDetail
	 * @summary 活動內頁
	 * @request GET:/api/v1/event/{id}
	 */
	v1EventDetail = (id: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: EventShowResource[];
				};
			},
			{
				/** @example 404 */
				status?: number;
				/** @example "查無此活動資料" */
				message?: string;
				/** @example "event_not_found" */
				error_code?: string;
				/** @example [] */
				extra?: string[];
			}
		>({
			path: `/api/v1/event/${id}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags form
	 * @name V1FormSubmitContactCreate
	 * @summary 聯絡我們送出表單
	 * @request POST:/api/v1/form/submit/contact
	 * @secure
	 */
	v1FormSubmitContactCreate = (
		data: V1FormSubmitContactCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				/** @example {"data":null} */
				data?: object | null;
			},
			{
				/** @example 422 */
				status?: number;
				/** @example "The given data was invalid." */
				message?: string;
				/** @example "validation_error" */
				error_code?: string;
				extra?: {
					name?: string[];
				};
			}
		>({
			path: `/api/v1/form/submit/contact`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.FormData,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags form
	 * @name V1FormSubmitRegisterCreate
	 * @summary 行程報名送出表單
	 * @request POST:/api/v1/form/submit/register
	 * @secure
	 */
	v1FormSubmitRegisterCreate = (
		data: V1FormSubmitRegisterCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				/** @example {"data":null} */
				data?: object | null;
			},
			| {
					/** @example 401 */
					status?: number;
					/** @example "未通過驗證" */
					message?: string;
					/** @example "verify_token_incorrect" */
					error_code?: string;
					/** @example [] */
					extra?: string[];
			  }
			| {
					/** @example 403 */
					status?: number;
					/** @example "行程未開放報名" */
					message?: string;
					/** @example "form_register_closed" */
					error_code?: string;
					/** @example [] */
					extra?: string[];
			  }
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						name?: string[];
					};
			  }
			| {
					/** @example 503 */
					status?: number;
					/** @example "行程報名出團查無行程資料" */
					message?: string;
					/** @example "form_register_tour_not_found" */
					error_code?: string;
					/** @example [] */
					extra?: string[];
			  }
		>({
			path: `/api/v1/form/submit/register`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.FormData,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags form
	 * @name V1FormSubmitEventCreate
	 * @summary 活動報名送出表單
	 * @request POST:/api/v1/form/submit/event
	 * @secure
	 */
	v1FormSubmitEventCreate = (
		data: V1FormSubmitEventCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				/** @example {"data":null} */
				data?: object | null;
			},
			| {
					/** @example 401 */
					status?: number;
					/** @example "未通過驗證" */
					message?: string;
					/** @example "verify_token_incorrect" */
					error_code?: string;
					/** @example [] */
					extra?: string[];
			  }
			| {
					/** @example 403 */
					status?: number;
					/** @example "活動未開放報名" */
					message?: string;
					/** @example "form_event_closed" */
					error_code?: string;
					/** @example [] */
					extra?: string[];
			  }
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						name?: string[];
					};
			  }
		>({
			path: `/api/v1/form/submit/event`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.FormData,
			format: 'json',
			...params,
		});
	/**
	 * @description 根據類型取得會員擁有的相對應表單
	 *
	 * @tags user
	 * @name V1UserFormDetail
	 * @summary 會員取得不同類型的表單內容
	 * @request GET:/api/v1/user/form/{type}
	 * @secure
	 */
	v1UserFormDetail = (
		{ type, ...query }: V1UserFormDetailRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: (FormContactResource | FormRegisterResource | FormEventResource)[];
				};
				/** 分頁連結 */
				link?: {
					/** 第一頁 */
					first?: string;
					/** 最後一頁 */
					last?: string;
					/** 上一頁 */
					prev?: string;
					/** 下一頁 */
					next?: string;
				};
				/** 分頁資訊 */
				meta?: {
					/** 目前回傳資料的頁數 */
					current_page?: number;
					/** 回傳資料從第幾筆開始 */
					from?: number;
					/** 可取得資料的最後一頁頁數 */
					last_page?: number;
					/** 當前 path */
					path?: string;
					/** 每頁幾筆 */
					per_page?: number;
					/** 回傳資料的最後一筆是第幾頁 */
					to?: number;
					/** 總頁數 */
					total?: number;
				};
			},
			{
				/** @example 401 */
				status?: number;
				/** @example "無操作權限" */
				message?: string;
				/** @example "unauthenticated" */
				error_code?: string;
				/** @example [] */
				extra?: string[];
			}
		>({
			path: `/api/v1/user/form/${type}`,
			method: 'GET',
			query: query,
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags home_page
	 * @name V1HomePageList
	 * @request GET:/api/v1/home-page
	 */
	v1HomePageList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: HomePageCollection;
			},
			any
		>({
			path: `/api/v1/home-page`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags praise
	 * @name V1PraiseList
	 * @request GET:/api/v1/praise
	 */
	v1PraiseList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: PraiseResource[];
				};
			},
			any
		>({
			path: `/api/v1/praise`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 上線中優惠方案列表資訊
	 *
	 * @tags promotional_offer
	 * @name V1PromotionalOffersList
	 * @summary 優惠方案列表
	 * @request GET:/api/v1/promotional-offers
	 */
	v1PromotionalOffersList = (
		query: V1PromotionalOffersListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: PromotionalOfferResource[];
					/** 分頁連結 */
					link?: {
						/** 第一頁 */
						first?: string;
						/** 最後一頁 */
						last?: string;
						/** 上一頁 */
						prev?: string;
						/** 下一頁 */
						next?: string;
					};
					/** 分頁資訊 */
					meta?: {
						/** 目前回傳資料的頁數 */
						current_page?: number;
						/** 回傳資料從第幾筆開始 */
						from?: number;
						/** 可取得資料的最後一頁頁數 */
						last_page?: number;
						/** 當前 path */
						path?: string;
						/** 每頁幾筆 */
						per_page?: number;
						/** 回傳資料的最後一筆是第幾頁 */
						to?: number;
						/** 總頁數 */
						total?: number;
					};
				};
			},
			any
		>({
			path: `/api/v1/promotional-offers`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 上線中優惠方案內頁詳細資訊
	 *
	 * @tags promotional_offer
	 * @name V1PromotionalOfferDetail
	 * @summary 優惠方案內頁
	 * @request GET:/api/v1/promotional-offer/{id}
	 */
	v1PromotionalOfferDetail = (id: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: PromotionalOfferResource[];
				};
			},
			{
				/** @example 404 */
				status?: number;
				/** @example "查無此優惠方案資料" */
				message?: string;
				/** @example "promotional_offer_not_found" */
				error_code?: string;
				/** @example [] */
				extra?: string[];
			}
		>({
			path: `/api/v1/promotional-offer/${id}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 行程總覽資訊
	 *
	 * @tags tour
	 * @name V1ToursList
	 * @summary 行程總覽
	 * @request GET:/api/v1/tours
	 */
	v1ToursList = (query: V1ToursListRequestParams, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: TourIndexResource[];
					/** 分頁連結 */
					link?: {
						/** 第一頁 */
						first?: string;
						/** 最後一頁 */
						last?: string;
						/** 上一頁 */
						prev?: string;
						/** 下一頁 */
						next?: string;
					};
					/** 分頁資訊 */
					meta?: {
						/** 目前回傳資料的頁數 */
						current_page?: number;
						/** 回傳資料從第幾筆開始 */
						from?: number;
						/** 可取得資料的最後一頁頁數 */
						last_page?: number;
						/** 當前 path */
						path?: string;
						/** 每頁幾筆 */
						per_page?: number;
						/** 回傳資料的最後一筆是第幾頁 */
						to?: number;
						/** 總頁數 */
						total?: number;
					};
				};
			},
			any
		>({
			path: `/api/v1/tours`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 所有行程總覽
	 *
	 * @tags tour
	 * @name v1AllToursList
	 * @summary 所有行程總覽
	 * @request GET:/api/v1/AllTours
	 */
	v1AllToursList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: TourIndexResource[];
					/** 分頁連結 */
					link?: {
						/** 第一頁 */
						first?: string;
						/** 最後一頁 */
						last?: string;
						/** 上一頁 */
						prev?: string;
						/** 下一頁 */
						next?: string;
					};
					/** 分頁資訊 */
					meta?: {
						/** 目前回傳資料的頁數 */
						current_page?: number;
						/** 回傳資料從第幾筆開始 */
						from?: number;
						/** 可取得資料的最後一頁頁數 */
						last_page?: number;
						/** 當前 path */
						path?: string;
						/** 每頁幾筆 */
						per_page?: number;
						/** 回傳資料的最後一筆是第幾頁 */
						to?: number;
						/** 總頁數 */
						total?: number;
					};
				};
			},
			any
		>({
			path: `/api/v1/AllTours`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/*
	 * @description 行程內頁詳細資訊
	 *
	 * @tags tour
	 * @name V1TourDetail
	 * @summary 行程內頁
	 * @request GET:/api/v1/tour/{id}
	 */
	v1TourDetail = (id: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: TourResource[];
				};
			},
			{
				/** @example 404 */
				status?: number;
				/** @example "查無此行程資料" */
				message?: string;
				/** @example "tour_not_found" */
				error_code?: string;
				/** @example [] */
				extra?: string[];
			}
		>({
			path: `/api/v1/tour/${id}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 出團總表資訊
	 *
	 * @tags tour_group
	 * @name V1TourGroupsList
	 * @summary 出團總表
	 * @request GET:/api/v1/tour-groups
	 */
	v1TourGroupsList = (query: V1TourGroupsListRequestParams, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: TourGroupIndexResource[];
					/** 分頁連結 */
					link?: {
						/** 第一頁 */
						first?: string;
						/** 最後一頁 */
						last?: string;
						/** 上一頁 */
						prev?: string;
						/** 下一頁 */
						next?: string;
					};
					/** 分頁資訊 */
					meta?: {
						/** 目前回傳資料的頁數 */
						current_page?: number;
						/** 回傳資料從第幾筆開始 */
						from?: number;
						/** 可取得資料的最後一頁頁數 */
						last_page?: number;
						/** 當前 path */
						path?: string;
						/** 每頁幾筆 */
						per_page?: number;
						/** 回傳資料的最後一筆是第幾頁 */
						to?: number;
						/** 總頁數 */
						total?: number;
					};
				};
			},
			any
		>({
			path: `/api/v1/tour-groups`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 出團內頁詳細資訊
	 *
	 * @tags tour_group
	 * @name V1TourGroupDetail
	 * @summary 出團內頁
	 * @request GET:/api/v1/tour-group/{id}
	 */
	v1TourGroupDetail = (id: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: TourGroupResource[];
				};
			},
			{
				/** @example 404 */
				status?: number;
				/** @example "查無此出團資料" */
				message?: string;
				/** @example "tour_group_not_found" */
				error_code?: string;
				/** @example [] */
				extra?: string[];
			}
		>({
			path: `/api/v1/tour-group/${id}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 旅遊專欄列表資訊
	 *
	 * @tags travel_column
	 * @name V1TravelColumnsList
	 * @summary 旅遊專欄列表
	 * @request GET:/api/v1/travel-columns
	 */
	v1TravelColumnsList = (query: V1TravelColumnsListRequestParams, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: TravelColumnResource[];
					/** 分頁連結 */
					link?: {
						/** 第一頁 */
						first?: string;
						/** 最後一頁 */
						last?: string;
						/** 上一頁 */
						prev?: string;
						/** 下一頁 */
						next?: string;
					};
					/** 分頁資訊 */
					meta?: {
						/** 目前回傳資料的頁數 */
						current_page?: number;
						/** 回傳資料從第幾筆開始 */
						from?: number;
						/** 可取得資料的最後一頁頁數 */
						last_page?: number;
						/** 當前 path */
						path?: string;
						/** 每頁幾筆 */
						per_page?: number;
						/** 回傳資料的最後一筆是第幾頁 */
						to?: number;
						/** 總頁數 */
						total?: number;
					};
				};
			},
			any
		>({
			path: `/api/v1/travel-columns`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 旅遊專欄內頁詳細資訊
	 *
	 * @tags travel_column
	 * @name V1TravelColumnDetail
	 * @summary 旅遊專欄內頁
	 * @request GET:/api/v1/travel-column/{id}
	 */
	v1TravelColumnDetail = (id: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: TravelColumnResource[];
				};
			},
			{
				/** @example 404 */
				status?: number;
				/** @example "查無此旅遊專欄資料" */
				message?: string;
				/** @example "travel_column_not_found" */
				error_code?: string;
				/** @example [] */
				extra?: string[];
			}
		>({
			path: `/api/v1/travel-column/${id}`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 取得行程對應旅遊小叮嚀資訊
	 *
	 * @tags tour
	 * @name V1TourTravelTipDetail
	 * @summary 行程旅遊小叮嚀
	 * @request GET:/api/v1/tour/{id}/travel-tip
	 */
	v1TourTravelTipDetail = (id: number, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: TravelTipShowResource[];
				};
			},
			any
		>({
			path: `/api/v1/tour/${id}/travel-tip`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags auth
	 * @name V1AuthUserCreate
	 * @summary 會員註冊
	 * @request POST:/api/v1/auth/user
	 * @secure
	 */
	v1AuthUserCreate = (data: V1AuthUserCreateRequestPayload, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				/** @example {"data":null} */
				data?: object | null;
			},
			| (
					| {
							/** @example 401 */
							status?: number;
							/** @example "未通過帳號檢查" */
							message?: string;
							/** @example "user_email_check_incorrect" */
							error_code?: string;
							/** @example [] */
							extra?: string[];
					  }
					| {
							/** @example 401 */
							status?: number;
							/** @example "未通過驗證" */
							message?: string;
							/** @example "verify_token_incorrect" */
							error_code?: string;
							/** @example [] */
							extra?: string[];
					  }
			  )
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						email?: string[];
						password?: string[];
						name?: string[];
						mobile?: string[];
						is_participated?: string[];
					};
			  }
		>({
			path: `/api/v1/auth/user`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.FormData,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags auth
	 * @name V1AuthLoginCreate
	 * @summary 會員登入
	 * @request POST:/api/v1/auth/login
	 */
	v1AuthLoginCreate = (data: V1AuthLoginCreateRequestPayload, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				/** @example {"data":null} */
				data?: object | null;
			},
			| {
					/** @example 400 */
					status?: number;
					/** @example "帳號或密碼錯誤" */
					message?: string;
					/** @example "login_failed" */
					error_code?: string;
					/** @example [] */
					extra?: string[];
			  }
			| {
					/** @example 403 */
					status?: number;
					/** @example "帳號已停權" */
					message?: string;
					/** @example "account_disable" */
					error_code?: string;
					/** @example [] */
					extra?: string[];
			  }
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						email?: string[];
						password?: string[];
					};
			  }
		>({
			path: `/api/v1/auth/login`,
			method: 'POST',
			body: data,
			type: ContentType.FormData,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags auth
	 * @name V1AuthLogoutCreate
	 * @summary 會員登出
	 * @request POST:/api/v1/auth/logout
	 * @secure
	 */
	v1AuthLogoutCreate = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				/** @example {"data":null} */
				data?: object | null;
			},
			{
				/** @example 400 */
				status?: number;
				/** @example "The given data was invalid." */
				message?: string;
				/** @example "bad_request" */
				error_code?: string;
			}
		>({
			path: `/api/v1/auth/logout`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags auth
	 * @name V1AuthRefreshTokenCreate
	 * @summary 會員更新 token
	 * @request POST:/api/v1/auth/refresh-token
	 * @secure
	 */
	v1AuthRefreshTokenCreate = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				/** @example {"data":null} */
				data?: object | null;
			},
			| {
					/** @example 400 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "bad_request" */
					error_code?: string;
			  }
			| {
					/** @example 401 */
					status?: number;
					/** @example "refresh token 已過期" */
					message?: string;
					/** @example "refresh_token_expired" */
					error_code?: string;
			  }
		>({
			path: `/api/v1/auth/refresh-token`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags auth
	 * @name V1AuthUserResetPasswordCreate
	 * @summary 會員重設密碼
	 * @request POST:/api/v1/auth/user/reset-password
	 * @secure
	 */
	v1AuthUserResetPasswordCreate = (
		data: V1AuthUserResetPasswordCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				/** @example {"data":null} */
				data?: object | null;
			},
			| (
					| {
							/** @example 401 */
							status?: number;
							/** @example "未通過帳號檢查" */
							message?: string;
							/** @example "user_email_check_incorrect" */
							error_code?: string;
							/** @example [] */
							extra?: string[];
					  }
					| {
							/** @example 401 */
							status?: number;
							/** @example "未通過驗證" */
							message?: string;
							/** @example "verify_token_incorrect" */
							error_code?: string;
							/** @example [] */
							extra?: string[];
					  }
			  )
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						email?: string[];
						password?: string[];
					};
			  }
		>({
			path: `/api/v1/auth/user/reset-password`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.FormData,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags auth
	 * @name V1AuthUserEmailCheckCreate
	 * @summary 會員信箱檢查
	 * @request POST:/api/v1/auth/user/email/check
	 */
	v1AuthUserEmailCheckCreate = (
		data: V1AuthUserEmailCheckCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				/** @example {"data":"eyJpdiI6IlpJcTFjc1pScEVEWmFRamkwMUJWbVE9PSIsInZhbHVlIjoidGZqS282bGlPMC9Wb0IreEt1UVE5ZjkrZENVY0tCby9hSndqU2VZL1Y5QUk2V2xra2dzeWhlVXc1ZkxBb3FxRyIsIm1hYyI6ImE3YzY5MzVjODFmMTFhYjhhMzViNTM4MjVhZmMwNzI0YjkxOWVmOGRkYzFhNGEwNmNiNzhiN2ZkNjM1NTU5YzYiLCJ0YWciOiIifQ=="} */
				data?: object | null;
			},
			| {
					/** @example 403 */
					status?: number;
					/** @example "帳號已停權" */
					message?: string;
					/** @example "account_disable" */
					error_code?: string;
					/** @example [] */
					extra?: string[];
			  }
			| {
					/** @example 404 */
					status?: number;
					/** @example "查無此帳號資料" */
					message?: string;
					/** @example "user_not_found" */
					error_code?: string;
					/** @example [] */
					extra?: string[];
			  }
			| {
					/** @example 409 */
					status?: number;
					/** @example "帳號已註冊" */
					message?: string;
					/** @example "has_registered" */
					error_code?: string;
					/** @example [] */
					extra?: string[];
			  }
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						email?: string[];
						check_type?: string[];
					};
			  }
		>({
			path: `/api/v1/auth/user/email/check`,
			method: 'POST',
			body: data,
			type: ContentType.FormData,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得會員個人資本資料
	 *
	 * @tags user
	 * @name V1UserList
	 * @summary 取得會員資料
	 * @request GET:/api/v1/user
	 * @secure
	 */
	v1UserList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: UserResource[];
				};
			},
			| {
					/** @example 401 */
					status?: number;
					/** @example "無操作權限" */
					message?: string;
					/** @example "unauthenticated" */
					error_code?: string;
					/** @example [] */
					extra?: string[];
			  }
			| {
					/** @example 401 */
					status?: number;
					/** @example "token 過期" */
					message?: string;
					/** @example "token_expired" */
					error_code?: string;
					/** @example [] */
					extra?: string[];
			  }
		>({
			path: `/api/v1/user`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 更新會員個人資本資料
	 *
	 * @tags user
	 * @name V1UserUpdate
	 * @summary 更新會員資料
	 * @request PUT:/api/v1/user
	 * @secure
	 */
	v1UserUpdate = (data: V1UserUpdateRequestPayload, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				/** @example {"data":null} */
				data?: object | null;
			},
			| (
					| {
							/** @example 401 */
							status?: number;
							/** @example "無操作權限" */
							message?: string;
							/** @example "unauthenticated" */
							error_code?: string;
							/** @example [] */
							extra?: string[];
					  }
					| {
							/** @example 401 */
							status?: number;
							/** @example "token 過期" */
							message?: string;
							/** @example "token_expired" */
							error_code?: string;
							/** @example [] */
							extra?: string[];
					  }
			  )
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						name?: string[];
						nickname?: string[];
						mobile?: string[];
						identity_number?: string[];
						birthday?: string[];
					};
			  }
		>({
			path: `/api/v1/user`,
			method: 'PUT',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 更新會員個人 email
	 *
	 * @tags user
	 * @name V1UserEmailUpdate
	 * @summary 更新會員資料 email
	 * @request PUT:/api/v1/user/email
	 * @secure
	 */
	v1UserEmailUpdate = (data: V1UserEmailUpdateRequestPayload, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				/** @example {"data":null} */
				data?: object | null;
			},
			| (
					| {
							/** @example 401 */
							status?: number;
							/** @example "未通過帳號檢查" */
							message?: string;
							/** @example "user_email_check_incorrect" */
							error_code?: string;
							/** @example [] */
							extra?: string[];
					  }
					| {
							/** @example 401 */
							status?: number;
							/** @example "未通過驗證" */
							message?: string;
							/** @example "verify_token_incorrect" */
							error_code?: string;
							/** @example [] */
							extra?: string[];
					  }
					| {
							/** @example 401 */
							status?: number;
							/** @example "無操作權限" */
							message?: string;
							/** @example "unauthenticated" */
							error_code?: string;
							/** @example [] */
							extra?: string[];
					  }
					| {
							/** @example 401 */
							status?: number;
							/** @example "token 過期" */
							message?: string;
							/** @example "token_expired" */
							error_code?: string;
							/** @example [] */
							extra?: string[];
					  }
			  )
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						email?: string[];
					};
			  }
		>({
			path: `/api/v1/user/email`,
			method: 'PUT',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags verify
	 * @name V1SendVerifyCodeCreate
	 * @summary 寄送驗證碼
	 * @request POST:/api/v1/sendVerifyCode
	 */
	v1SendVerifyCodeCreate = (
		data: V1SendVerifyCodeCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				/** @example {"data":null} */
				data?: object | null;
			},
			| {
					/** @example 409 */
					status?: number;
					/** @example "重複寄送驗證碼" */
					message?: string;
					/** @example "verify_code_sending_already" */
					error_code?: string;
					/** @example [] */
					extra?: string[];
			  }
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						email?: string[];
					};
			  }
			| {
					/** @example 503 */
					status?: number;
					/** @example "寄送驗證碼發生錯誤" */
					message?: string;
					/** @example "verify_code_sending_failed" */
					error_code?: string;
					/** @example [] */
					extra?: string[];
			  }
		>({
			path: `/api/v1/sendVerifyCode`,
			method: 'POST',
			body: data,
			type: ContentType.FormData,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags verify
	 * @name V1VerifyCodeCreate
	 * @summary 驗證驗證碼
	 * @request POST:/api/v1/verifyCode
	 */
	v1VerifyCodeCreate = (data: V1VerifyCodeCreateRequestPayload, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				/** @example {"data":"eyJpdiI6IkIyS2lSbzRXNEpmR3ZYRlZ4ejdrVWc9PSIsInZhbHVlIjoicHBVK3QzVzRtV2dUSlduRGVyem05QT09IiwibWFjIjoiOTVkYzczMzM3ZjRhNTIzNGNhYTJmYjMzODkwMTgwYjc4NTEzZGRiYTMwOWEwMThiMWQ3YmU3ZjUzNmEwNmZjNSIsInRhZyI6IiJ9"} */
				data?: object;
			},
			| {
					/** @example 400 */
					status?: number;
					/** @example "驗證碼錯誤" */
					message?: string;
					/** @example "verify_code_incorrect" */
					error_code?: string;
					/** @example [] */
					extra?: string[];
			  }
			| {
					/** @example 404 */
					status?: number;
					/** @example "驗證碼已失效" */
					message?: string;
					/** @example "verify_code_expired" */
					error_code?: string;
					/** @example [] */
					extra?: string[];
			  }
			| {
					/** @example 422 */
					status?: number;
					/** @example "The given data was invalid." */
					message?: string;
					/** @example "validation_error" */
					error_code?: string;
					extra?: {
						email?: string[];
						verify_type?: string[];
						verify_code?: string[];
					};
			  }
		>({
			path: `/api/v1/verifyCode`,
			method: 'POST',
			body: data,
			type: ContentType.FormData,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags tag
	 * @name V1TagList
	 * @request GET:/api/v1/tag
	 */
	v1TagList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: TagIndexResource[];
				};
			},
			any
		>({
			path: `/api/v1/tag`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags tag
	 * @name V1TagTourStyleList
	 * @request GET:/api/v1/tag/tour-style
	 */
	v1TagTourStyleList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: TagTourStyleCollection[];
				};
			},
			any
		>({
			path: `/api/v1/tag/tour-style`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags banner
	 * @name V1BannerList
	 * @request GET:/api/v1/banner
	 */
	v1BannerList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: BannerResource[];
				};
			},
			any
		>({
			path: `/api/v1/banner`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * @description 後台登入用，會取得 JWT Token
	 *
	 * @tags bscore_admin_auth
	 * @name BscoreLoginCreate
	 * @request POST:/api/bscore/login
	 */
	bscoreLoginCreate = (data: BscoreLoginCreateRequestPayload, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/**
						 * JWT token
						 * @example "eyJ0eXAiOiJ..."
						 */
						token?: string;
						/** 模組 route 列表，提供跳轉用 */
						default?: string[];
					};
				};
			},
			any
		>({
			path: `/api/bscore/login`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description Refresh Token
	 *
	 * @tags bscore_admin_auth
	 * @name BscoreRefreshTokenCreate
	 * @request POST:/api/bscore/refresh-token
	 * @secure
	 */
	bscoreRefreshTokenCreate = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/**
						 * JWT token
						 * @example "eyJ0eXAiOiJ..."
						 */
						token?: string;
					};
				};
			},
			any
		>({
			path: `/api/bscore/refresh-token`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 變更密碼
	 *
	 * @tags bscore_admin_auth
	 * @name BscoreResetPasswordCreate
	 * @request POST:/api/bscore/resetPassword
	 * @secure
	 */
	bscoreResetPasswordCreate = (
		data: BscoreResetPasswordCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/resetPassword`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_admin_user
	 * @name BscoreMeList
	 * @summary 取得目前使用者的資料
	 * @request GET:/api/bscore/me
	 * @secure
	 */
	bscoreMeList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: AdminUserResource;
				};
			},
			any
		>({
			path: `/api/bscore/me`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 重置使用者，並寄送驗證信
	 *
	 * @tags bscore_admin_user
	 * @name BscoreBscoreAdminUserInitAccountCreate
	 * @request POST:/api/bscore/bscore:admin-user/init-account
	 * @secure
	 */
	bscoreBscoreAdminUserInitAccountCreate = (
		adminUser: string,
		data: BscoreBscoreAdminUserInitAccountCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/bscore${adminUser}/init-account`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 寄送帳號解鎖信
	 *
	 * @tags bscore_admin_user
	 * @name BscoreBscoreAdminUserUnlockAccountCreate
	 * @request POST:/api/bscore/bscore:admin-user/unlock-account
	 * @secure
	 */
	bscoreBscoreAdminUserUnlockAccountCreate = (
		adminUser: string,
		data: BscoreBscoreAdminUserUnlockAccountCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/bscore${adminUser}/unlock-account`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得帳號解鎖信 user 資料
	 *
	 * @tags bscore_admin_user
	 * @name BscoreGetUnlockMailInfoList
	 * @request GET:/api/bscore/get-unlock-mail-info
	 */
	bscoreGetUnlockMailInfoList = (
		query: BscoreGetUnlockMailInfoListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: AdminUserResource;
				};
			},
			any
		>({
			path: `/api/bscore/get-unlock-mail-info`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 解鎖帳號
	 *
	 * @tags bscore_admin_user
	 * @name BscoreUnlockAccountCreate
	 * @request POST:/api/bscore/unlock-account
	 */
	bscoreUnlockAccountCreate = (
		data: BscoreUnlockAccountCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/unlock-account`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得信箱驗證信 user 資料
	 *
	 * @tags bscore_admin_user
	 * @name BscoreGetVerifyMailInfoList
	 * @request GET:/api/bscore/get-verify-mail-info
	 */
	bscoreGetVerifyMailInfoList = (
		query: BscoreGetVerifyMailInfoListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: AdminUserResource;
				};
			},
			any
		>({
			path: `/api/bscore/get-verify-mail-info`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 驗證帳號
	 *
	 * @tags bscore_admin_user
	 * @name BscoreVerifyAccountCreate
	 * @request POST:/api/bscore/verify-account
	 */
	bscoreVerifyAccountCreate = (
		data: BscoreVerifyAccountCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/verify-account`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 忘記密碼信
	 *
	 * @tags bscore_admin_user
	 * @name BscoreForgetPasswordCreate
	 * @request POST:/api/bscore/forget-password
	 */
	bscoreForgetPasswordCreate = (
		data: BscoreForgetPasswordCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						next_can_send?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/forget-password`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 忘記密碼 - 重設密碼資訊
	 *
	 * @tags bscore_admin_user
	 * @name BscoreGetForgetMailInfoList
	 * @request GET:/api/bscore/get-forget-mail-info
	 */
	bscoreGetForgetMailInfoList = (
		query: BscoreGetForgetMailInfoListRequestParams,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: AdminUserResource;
				};
			},
			any
		>({
			path: `/api/bscore/get-forget-mail-info`,
			method: 'GET',
			query: query,
			format: 'json',
			...params,
		});
	/**
	 * @description 忘記密碼 - 重設密碼
	 *
	 * @tags bscore_admin_user
	 * @name BscoreResetPasswordFromMailCreate
	 * @request POST:/api/bscore/reset-password-from-mail
	 */
	bscoreResetPasswordFromMailCreate = (
		data: BscoreResetPasswordFromMailCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/reset-password-from-mail`,
			method: 'POST',
			body: data,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreGetModuleConfigDetail
	 * @summary 取得選單對應的模組 config
	 * @request GET:/api/bscore/{menu_item_key}/get-module-config
	 * @secure
	 */
	bscoreGetModuleConfigDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<GetDataCreate, any>({
			path: `/api/bscore/${menuItemKey}/get-module-config`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreGetSearchableDetail
	 * @summary 取得進階搜尋欄位
	 * @request GET:/api/bscore/{menu_item_key}/get-searchable
	 * @secure
	 */
	bscoreGetSearchableDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<AdvanceSearchable[], any>({
			path: `/api/bscore/${menuItemKey}/get-searchable`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 一般搜尋
	 *
	 * @tags bscore
	 * @name BscoreSearchCreate
	 * @request POST:/api/bscore/{menu_item_key}/search
	 * @secure
	 */
	bscoreSearchCreate = (menuItemKey: string, data: SearchRequest, params: RequestParams = {}) =>
		this.http.request<GetListDataPaginator, any>({
			path: `/api/bscore/${menuItemKey}/search`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreAdvanceSearchCreate
	 * @summary 進階搜尋
	 * @request POST:/api/bscore/{menu_item_key}/advance-search
	 * @secure
	 */
	bscoreAdvanceSearchCreate = (
		menuItemKey: string,
		data: AdvanceSearchRequest,
		params: RequestParams = {},
	) =>
		this.http.request<GetListDataPaginator, any>({
			path: `/api/bscore/${menuItemKey}/advance-search`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreCreateDetail
	 * @summary 新增資料頁顯示
	 * @request GET:/api/bscore/{menu_item_key}/create
	 * @secure
	 */
	bscoreCreateDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<GetDataCreate, any>({
			path: `/api/bscore/${menuItemKey}/create`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreEditDetail
	 * @summary 編輯資料頁顯示
	 * @request GET:/api/bscore/{menu_item_key}/{id}/edit
	 * @secure
	 */
	bscoreEditDetail = (menuItemKey: string, id: string, params: RequestParams = {}) =>
		this.http.request<GetDataUpdate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/edit`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreCreate
	 * @summary 新增
	 * @request POST:/api/bscore/{menu_item_key}
	 * @secure
	 */
	bscoreCreate = (menuItemKey: string, data: GetDataCreate, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						id?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreUpdate
	 * @summary 更新
	 * @request PUT:/api/bscore/{menu_item_key}/{id}
	 * @secure
	 */
	bscoreUpdate = (
		menuItemKey: string,
		id: string,
		data: GetDataUpdate,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						id?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}`,
			method: 'PUT',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreDelete
	 * @summary 刪除
	 * @request DELETE:/api/bscore/{menu_item_key}/{id}
	 * @secure
	 */
	bscoreDelete = (menuItemKey: string, id: string, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}`,
			method: 'DELETE',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreCopyCreate
	 * @summary 複製
	 * @request POST:/api/bscore/{menu_item_key}/copy
	 * @secure
	 */
	bscoreCopyCreate = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						id?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/copy`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreDestroyAllCreate
	 * @summary 批次刪除
	 * @request POST:/api/bscore/{menu_item_key}/destroy-all
	 * @secure
	 */
	bscoreDestroyAllCreate = (
		menuItemKey: string,
		data: BscoreDestroyAllCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/destroy-all`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreUpdateRowCreate
	 * @summary 列表單一行更新
	 * @request POST:/api/bscore/{menu_item_key}/update-row
	 * @secure
	 */
	bscoreUpdateRowCreate = (
		menuItemKey: string,
		data: BscoreUpdateRowCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/update-row`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreUpdateListCreate
	 * @summary reorder/update status
	 * @request POST:/api/bscore/{menu_item_key}/update-list
	 * @secure
	 */
	bscoreUpdateListCreate = (
		{ menuItemKey, ...query }: BscoreUpdateListCreateRequestParams,
		data: BscoreUpdateListCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/update-list`,
			method: 'POST',
			query: query,
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得 Menu Item 對應的 Dashboard Config
	 *
	 * @tags bscore_dashboard
	 * @name BscoreGetDashboardConfigDetail
	 * @request GET:/api/bscore/{menu_item_key}/get-dashboard-config
	 * @secure
	 */
	bscoreGetDashboardConfigDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/get-dashboard-config`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得 Metric Key 對應的 Metric
	 *
	 * @tags bscore_dashboard
	 * @name BscoreMetricDetail
	 * @request GET:/api/bscore/metric/{metric_key}
	 * @secure
	 */
	bscoreMetricDetail = (metricKey: string, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/metric/${metricKey}`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreExcelExportCreate
	 * @summary 匯出
	 * @request POST:/api/bscore/{menu_item_key}/excel-export
	 * @secure
	 */
	bscoreExcelExportCreate = (
		menuItemKey: string,
		data: ExcelSearchRequest,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						taskId?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/excel-export`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListExcelExportCreate
	 * @summary SubList 匯出
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/excel-export
	 * @secure
	 */
	bscoreSubListExcelExportCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: ExcelSearchRequest,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						taskId?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/excel-export`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreExcelImportCreate
	 * @summary 匯入
	 * @request POST:/api/bscore/{menu_item_key}/excel-import
	 * @secure
	 */
	bscoreExcelImportCreate = (
		menuItemKey: string,
		data: ImportRequest,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						taskId?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/excel-import`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.FormData,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreExcelImportUploadUrlCreate
	 * @summary 取得匯入用上傳檔案網址
	 * @request POST:/api/bscore/{menu_item_key}/excel-import-upload-url
	 * @secure
	 */
	bscoreExcelImportUploadUrlCreate = (
		menuItemKey: string,
		data: BscoreExcelImportUploadUrlCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						url?: string;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/excel-import-upload-url`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListExcelImportCreate
	 * @summary SubList 匯入
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/excel-import
	 * @secure
	 */
	bscoreSubListExcelImportCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: ImportRequest,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						taskId?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/excel-import`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.FormData,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListExcelImportUploadUrlCreate
	 * @summary 取得 SubList 匯入用上傳檔案網址
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/excel-import-upload-url
	 * @secure
	 */
	bscoreSubListExcelImportUploadUrlCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: BscoreSubListExcelImportUploadUrlCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						url?: string;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/excel-import-upload-url`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreExcelImportExampleFileCreate
	 * @summary 下載匯入範例檔案
	 * @request POST:/api/bscore/{menu_item_key}/excel-import-example-file
	 * @secure
	 */
	bscoreExcelImportExampleFileCreate = (
		menuItemKey: string,
		data: BscoreExcelImportExampleFileCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<void, any>({
			path: `/api/bscore/${menuItemKey}/excel-import-example-file`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListExcelImportExampleFileCreate
	 * @summary 下載 SubList 匯入範例檔案
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/excel-import-example-file
	 * @secure
	 */
	bscoreSubListExcelImportExampleFileCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: BscoreSubListExcelImportExampleFileCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<void, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/excel-import-example-file`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			...params,
		});
	/**
	 * @description 列表頁側邊欄，列出 Menu 和 MenuItem
	 *
	 * @tags bscore_menu
	 * @name BscoreSubMenuNaviList
	 * @request GET:/api/bscore/sub-menu-navi
	 * @secure
	 */
	bscoreSubMenuNaviList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: MenuResource[];
				};
			},
			any
		>({
			path: `/api/bscore/sub-menu-navi`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 列表頁側邊欄，沒有 Menu 的 Menuitem 會在此列出
	 *
	 * @tags bscore_menu
	 * @name BscoreMenuItemNaviList
	 * @request GET:/api/bscore/menu-item-navi
	 * @secure
	 */
	bscoreMenuItemNaviList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: MenuItemResource[];
				};
			},
			any
		>({
			path: `/api/bscore/menu-item-navi`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 用於列表頁側邊欄置底的 Menu 和 MenuItem
	 *
	 * @tags bscore_menu
	 * @name BscoreBottomMenuNaviList
	 * @request GET:/api/bscore/bottom-menu-navi
	 * @secure
	 */
	bscoreBottomMenuNaviList = (params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						menus?: MenuResource[];
						menuItems?: MenuItemResource[];
					};
				};
			},
			any
		>({
			path: `/api/bscore/bottom-menu-navi`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_menu
	 * @name BscoreInfoDetail
	 * @summary 提供前端 menu_item_key 的資訊
	 * @request GET:/api/bscore/{menu_item_key}/info
	 * @secure
	 */
	bscoreInfoDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/info`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * @description 取得後台設定
	 *
	 * @tags bscore
	 * @name BscoreClientSettingsList
	 * @request GET:/api/bscore/client-settings
	 */
	bscoreClientSettingsList = (params: RequestParams = {}) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/client-settings`,
			method: 'GET',
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_setting_sub_list
	 * @name BscoreSettingsEditDetail
	 * @summary 設定編輯頁顯示
	 * @request GET:/api/bscore/{menu_item_key}/settings/edit
	 * @secure
	 */
	bscoreSettingsEditDetail = (menuItemKey: string, params: RequestParams = {}) =>
		this.http.request<BscoreImportResult, any>({
			path: `/api/bscore/${menuItemKey}/settings/edit`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_setting_sub_list
	 * @name BscoreSettingsUpdate
	 * @summary 設定編輯頁更新
	 * @request PUT:/api/bscore/{menu_item_key}/settings
	 * @secure
	 */
	bscoreSettingsUpdate = (
		menuItemKey: string,
		data: BscoreSettingsUpdateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<BscoreImportResult, any>({
			path: `/api/bscore/${menuItemKey}/settings`,
			method: 'PUT',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListSearchCreate
	 * @summary SubList 一般搜尋
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/search
	 * @secure
	 */
	bscoreSubListSearchCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: SearchRequest,
		params: RequestParams = {},
	) =>
		this.http.request<GetListDataPaginator, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/search`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListCreateDetail
	 * @summary SubList 新增資料頁顯示
	 * @request GET:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/create
	 * @secure
	 */
	bscoreSubListCreateDetail = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/create`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListEditDetail
	 * @summary SubList 編輯資料頁顯示
	 * @request GET:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/{sub_id}/edit
	 * @secure
	 */
	bscoreSubListEditDetail = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		subId: string,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/${subId}/edit`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListCreate
	 * @summary SubList 新增
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}
	 * @secure
	 */
	bscoreSubListCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						id?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}`,
			method: 'POST',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListUpdate
	 * @summary SubList 更新
	 * @request PUT:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/{sub_id}
	 * @secure
	 */
	bscoreSubListUpdate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		subId: string,
		params: RequestParams = {},
	) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						/** @example 1 */
						id?: number;
					};
				};
			},
			any
		>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/${subId}`,
			method: 'PUT',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListDelete
	 * @summary SubList 刪除
	 * @request DELETE:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/{sub_id}
	 * @secure
	 */
	bscoreSubListDelete = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		subId: string,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/${subId}`,
			method: 'DELETE',
			secure: true,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListDestroyAllCreate
	 * @summary SubList 批次刪除
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/destroy-all
	 * @secure
	 */
	bscoreSubListDestroyAllCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: BscoreSubListDestroyAllCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/destroy-all`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListUpdateRowCreate
	 * @summary SubList 列表單一行更新
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/update-row
	 * @secure
	 */
	bscoreSubListUpdateRowCreate = (
		menuItemKey: string,
		id: string,
		subListKey: string,
		data: BscoreSubListUpdateRowCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/update-row`,
			method: 'POST',
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore_sub_list
	 * @name BscoreSubListUpdateListCreate
	 * @summary SubList reorder/update status
	 * @request POST:/api/bscore/{menu_item_key}/{id}/sub-list/{sub_list_key}/update-list
	 * @secure
	 */
	bscoreSubListUpdateListCreate = (
		{ menuItemKey, id, subListKey, ...query }: BscoreSubListUpdateListCreateRequestParams,
		data: BscoreSubListUpdateListCreateRequestPayload,
		params: RequestParams = {},
	) =>
		this.http.request<ApiResponseTemplate, any>({
			path: `/api/bscore/${menuItemKey}/${id}/sub-list/${subListKey}/update-list`,
			method: 'POST',
			query: query,
			body: data,
			secure: true,
			type: ContentType.Json,
			format: 'json',
			...params,
		});
	/**
	 * No description
	 *
	 * @tags bscore
	 * @name BscoreTaskDetail
	 * @summary 取得 task 狀態
	 * @request GET:/api/bscore/task/{task}
	 * @secure
	 */
	bscoreTaskDetail = (task: string, params: RequestParams = {}) =>
		this.http.request<
			ApiResponseTemplate & {
				data?: {
					data?: {
						status?: 'WAITING' | 'PROCESSING' | 'FINISH' | 'FAILED';
						result?: BscoreExportResult | BscoreImportResult;
					};
				};
			},
			any
		>({
			path: `/api/bscore/task/${task}`,
			method: 'GET',
			secure: true,
			format: 'json',
			...params,
		});
}
