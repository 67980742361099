import React from 'react';
import { CustomRoute } from 'util/hook/useRouter';

import { getAllToursList, clearTourFilter } from 'models/tour';

const routes: CustomRoute = {
	path: '/countries',
	components: () => [import('./component')],
	render: (_, children) => children,
	onEnter: async ({ next }) => {
		console.log('on Enter countries');
		const children = await next();
		console.log('on Enter countries / end');

		return children;
	},
	children: [
		{
			path: '',
			components: () => [import('./component')],
			render: ([Countries]) => <Countries />,
			onEnter: async ({ next, store }) => {
				store.dispatch(clearTourFilter());

				await store.dispatch(getAllToursList());

				const children = await next();
				return children;
			},
		},
	],
};

export default routes;
