import React from 'react';
import classNames from 'classnames';

import styles from './index.module.css';

interface DividerProperty {
	className?: string;
}

const DividerSimple: React.FC<DividerProperty> = ({ className }) => (
	<div className={classNames(styles.divider, className)} />
);

export default DividerSimple;
