import React from 'react';
import classnames from 'classnames';

import Link from 'components/atoms/Link';

import styles from './index.module.css';

interface TagsResource {
	id: number;
	name: string;
}

interface TourGuideTableItemOnlyTagsProperty {
	className?: string;
	data: TagsResource[];
}

const TourGuideTableItemOnlyTags: React.FC<TourGuideTableItemOnlyTagsProperty> = ({
	className,
	data,
}) => (
	<div className={classnames(styles.tourGuideItem, className)}>
		{data.map(tags => (
			<Link key={tags.id} to="/tours" query={{ tags: tags.name }} className={styles.link}>
				<div key={tags.id} className={styles.country}>
					<div className={styles.circleContainer}>
						<div className={styles.circle} />
					</div>
					<div className={styles.item}>{tags.name}</div>
				</div>
			</Link>
		))}
	</div>
);

export default TourGuideTableItemOnlyTags;
