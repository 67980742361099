import React from 'react';
import classnames from 'classnames';

import Link from 'components/atoms/Link';
import { CountryIndexResource } from 'util/api/swaggerApi/data-contracts';

import styles from './index.module.css';

interface TourGuideTableItemProperty {
	className?: string;
	areas: string[];
	countryData: CountryIndexResource[];
}

const TourGuideTableItem: React.FC<TourGuideTableItemProperty> = ({
	className,
	areas,
	countryData,
}) => {
	// 篩掉重複的國家 包含順序不同的陣列
	const uniqueDataCountry = countryData
		.reduce((acc, current) => {
			// 對國家進行排序，以便比較
			const sortedCountries = current.countries
				.map((country: { name: unknown }) => country.name)
				.sort()
				.join(',');

			// 檢查是否已存在相同的國家組合
			if (!acc.find(item => item.sortedCountries === sortedCountries)) {
				acc.push({ ...current, sortedCountries });
			}

			return acc;
		}, [])
		.map(({ sortedCountries, ...rest }) => rest); // 去掉 sortedCountries 屬性

	// 根據 areas 過濾國家
	function categorizeCountriesByFirstArea(data: CountryIndexResource[]) {
		const result: { area: string; countryNames: string[] }[] = [];
		const areaMap: Record<string, Set<string>> = {}; // 使用 Set 來過濾重複

		data.forEach(item => {
			if (item.countries && item.countries.length > 0) {
				const firstArea = item.countries[0].area;
				const countryNames = item.countries
					.map((country: { name: unknown }) => country.name)
					.join('．');

				// 將國家名稱字串轉換為標準化格式
				const sortedCountries = countryNames.split('、').sort().join('、');
				if (!areaMap[firstArea]) {
					areaMap[firstArea] = new Set();
				}

				// 將標準化的字串加到 Set 中
				areaMap[firstArea].add(sortedCountries);
			}
		});

		Object.entries(areaMap).forEach(([area, countryNamesSet]) => {
			result.push({ area, countryNames: Array.from(countryNamesSet) });
		});

		return result;
	}

	const filterArea = categorizeCountriesByFirstArea(uniqueDataCountry);
	const filteredCountries = filterArea.filter(country => areas.includes(country.area));

	return (
		<div className={classnames(styles.tourGuideItem, className)}>
			{filteredCountries.map(country => (
				<div key={country.area}>
					{/* 只取錢8個 */}
					{country.countryNames.slice(0, 8).map((name, i) => (
						<Link
							key={country.area}
							to="/tours"
							className={styles.link}
							query={{ countries: name.split('．').join(',') }} // 將 query 中的 name 用逗號分隔
						>
							<div className={styles.country}>
								<div className={styles.circleContainer}>
									<div className={styles.circle} />
								</div>
								<div className={styles.item}>{name}</div>
							</div>
						</Link>
					))}
				</div>
			))}
		</div>
	);
};

export default TourGuideTableItem;
