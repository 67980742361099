import { createAction, handleActions, Action } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { Dispatch } from 'redux';
import { api } from 'util/api';
import {
	TourResource,
	TourIndexResource,
	TourGroupResource,
	TourGroupIndexResource,
	TravelTipShowResource,
} from 'util/api/swaggerApi/data-contracts';

import { GetState, State as GlobalState } from './reducers';

export interface MetaDataProperty {
	currentPage?: number;
	from?: number;
	lastPage?: number;
	path?: string;
	perPage?: number;
	to?: number;
	total?: number;
}

/* Tour 行程資料 */
export const getToursList = createAction<
	(
		dispatch: Dispatch,
		getState: GetState,
	) => Promise<{ data: TourIndexResource[]; meta: MetaDataProperty }>,
	number
		>('GET_TOURS_LIST', page => async (_, getState) => {
			const {
				tours: {
					filter: { countries, tags, dateStart, dateEnd },
				},
			} = getState();

			const { v1ToursList } = api;
			const { data, message } = await v1ToursList({
				countries,
				tags,
				date_start: dateStart,
				date_end: dateEnd,
				page,
			});

			if (message !== 'success') {
				return { data: [], meta: {} };
			}

			return {
				data: data?.data as TourIndexResource[],
				meta: data?.meta as MetaDataProperty,
			};
		});

/* 所有行程資料 */
export const getAllToursList = createAction<
	(
		dispatch: Dispatch,
		getState: GetState,
	) => Promise<{ data: TourIndexResource[]; meta: MetaDataProperty }>,
	void // 不需要傳入頁碼或其他參數
		>('GET_ALL_TOURS_LIST', () => async ()=> {
			const { v1AllToursList } = api;
			const { data, message } = await v1AllToursList();
			console.log("AllTours:",data);
			
			if (message !== 'success') {
				return { data: [], meta: {} }; // 返回空數據
			}

			return {
				data: data?.data as TourIndexResource[], // 返回行程數據
				meta: data?.meta as MetaDataProperty, // 返回分頁元數據
			};
		});

export const getTourDataById = createAction<Promise<TourResource>, number>(
	'GET_TOUR_DATA_BY_ID',
	async id => {
		const { v1TourDetail } = api;
		try {
			const { data, message } = await v1TourDetail(id);
	
			if (message !== 'success') {
				return {};
			}
	
			return data?.data as TourResource;
		} catch (error) {
			window.location.href = '/';
			return {};
		}
	},
);

export const getPopularToursList = createAction<Promise<TourResource[]>>(
	'GET_POPULAR_TOURS_LIST',
	async () => {
		const { v1ToursList } = api;
		const { data, message } = await v1ToursList({
			get_popular_tours: true,
		});

		if (message !== 'success') {
			return [];
		}

		return data?.data as TourResource[];
	}
);

/* Tour Departure 出團資料 */
export const getTourDeparturesList = createAction<
	(
		dispatch: Dispatch,
		getState: GetState,
	) => Promise<{ data: TourGroupIndexResource[]; meta: MetaDataProperty }>,
	number, // 當前頁數
	number | void, // 此筆出團資料對應的行程 id
	number | void // 每頁顯示筆數
		>('GET_TOUR_DEPARTURES_LIST', (page, tourId, perPage) => async (_, getState) => {
			const {
				tours: {
					filter: { countries, tags, dateStart, dateEnd },
				},
			} = getState();

			const { v1TourGroupsList } = api;
			const { data, message } = await v1TourGroupsList({
				countries,
				tour_id: tourId || undefined,
				tags,
				date_start: dateStart,
				date_end: dateEnd,
				pre_page: perPage || 16,
				page,
			});

			if (message !== 'success') {
				return { data: [], meta: {} };
			}

			return {
				data: data?.data as TourIndexResource[],
				meta: data?.meta as MetaDataProperty,
			};
		});

export const getTourDepartureDataById = createAction<Promise<TourGroupResource>, number>(
	'GET_TOUR_DEPARTURE_DATA_BY_ID',
	async id => {
		const { v1TourGroupDetail } = api;
		try {
			const { data, message } = await v1TourGroupDetail(id);
	
			if (message !== 'success') {
				return {};
			}
	
			return data?.data as TourGroupResource;
		} catch (error) {
			window.location.href = '/';
			return {};
		}
	},
);

export const getTravelTipById = createAction<Promise<TravelTipShowResource>, number>(
	'GET_TRAVEL_TIP_BY_ID',
	async id => {
		const { v1TourTravelTipDetail } = api;
		const { data, message } = await v1TourTravelTipDetail(id);

		if (message !== 'success') {
			return {};
		}

		return data?.data as TravelTipShowResource;
	},
);

/* Clear */
export const clearToursList = createAction('CLEAR_TOURS_LIST');

export const clearTourDeparturesList = createAction('CLEAR_TOUR_DEPARTURES_LIST');

/* Filter 篩選資料 */
export type TourFilterField = 'countries' | 'tags' | 'dateStart' | 'dateEnd';

export const setTourFilter = createAction<
	{ field: TourFilterField; value: string },
	{ field: TourFilterField; value: string }
>('SET_TOUR_FILTER', ({ field, value }) => ({ field, value }));

export const clearTourFilter = createAction('CLEAR_TOUR_FILTER');

export interface State {
	loading: boolean;
	toursList: TourIndexResource[];
	allToursList: TourIndexResource[];
	tourData: TourResource;
	tourDeparturesList: TourGroupResource[];
	tourDepartureData: TourGroupResource;
	popularToursList: TourResource[];
	travelTipsData: TravelTipShowResource;
	metaData: MetaDataProperty;
	filter: {
		countries: string;
		tags: string;
		dateStart: string;
		dateEnd: string;
	};
}

export const defaultState: State = {
	loading: false,
	toursList: [],
	allToursList:[],
	tourData: {},
	tourDeparturesList: [],
	tourDepartureData: {},
	popularToursList: [],
	travelTipsData: {},
	metaData: {},
	filter: {
		countries: '',
		tags: '',
		dateStart: '',
		dateEnd: '',
	},
};

export const reducer = {
	tours: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
		{
			GET_TOURS_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_TOURS_LIST_FULFILLED: (
				state,
				action: Action<{ data: TourIndexResource[]; meta: MetaDataProperty }>,
			) => ({
				...state,
				loading: false,
				toursList: action.payload.data,
				metaData: action.payload.meta,
			}),
			
			GET_ALL_TOURS_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_ALL_TOURS_LIST_FULFILLED: (
				state,
				action: Action<{ data: TourIndexResource[]}>,
			) => ({
				...state,
				loading: false,
				allToursList: action.payload.data,
			}),

			GET_TOUR_DATA_BY_ID_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_TOUR_DATA_BY_ID_FULFILLED: (state, action: Action<TourResource>) => ({
				...state,
				loading: false,
				tourData: action.payload,
			}),

			GET_POPULAR_TOURS_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_POPULAR_TOURS_LIST_FULFILLED: (state, action: Action<TourResource[]>) => ({
				...state,
				loading: false,
				popularToursList: action.payload,
			}),

			GET_TOUR_DEPARTURES_LIST_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_TOUR_DEPARTURES_LIST_FULFILLED: (
				state,
				action: Action<{ data: TourGroupIndexResource[]; meta: MetaDataProperty }>,
			) => ({
				...state,
				loading: false,
				tourDeparturesList: action.payload.data,
				metaData: action.payload.meta,
			}),

			GET_TOUR_DEPARTURE_DATA_BY_ID_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_TOUR_DEPARTURE_DATA_BY_ID_FULFILLED: (state, action: Action<TourGroupResource>) => ({
				...state,
				loading: false,
				tourDepartureData: action.payload,
			}),

			GET_TRAVEL_TIP_BY_ID_PENDING: state => ({
				...state,
				loading: true,
			}),

			GET_TRAVEL_TIP_BY_ID_FULFILLED: (state, action: Action<TravelTipShowResource>) => ({
				...state,
				loading: false,
				travelTipsData: action.payload,
			}),

			CLEAR_TOURS_LIST: state => ({
				...state,
				toursList: defaultState.toursList,
			}),

			CLEAR_TOUR_DEPARTURES_LIST: state => ({
				...state,
				tourDeparturesList: defaultState.tourDeparturesList,
			}),

			SET_TOUR_FILTER: (state, action: Action<{ field: TourFilterField; value: string }>) => ({
				...state,
				filter: {
					...state.filter,
					[action.payload.field]: action.payload.value,
				},
			}),

			CLEAR_TOUR_FILTER: state => ({
				...state,
				filter: defaultState.filter,
			}),
		},
		defaultState,
	),
};

const selectTour = (state: GlobalState) => ({
	toursList: state.tours.toursList,
	allToursList:state.tours.allToursList,
	tourData: state.tours.tourData,
	popularToursList: state.tours.popularToursList,
	travelTipsData: state.tours.travelTipsData,
	metaData: state.tours.metaData,
});

export const useTour = () =>
	useRedux(selectTour, { getToursList,getAllToursList, getTourDataById, getPopularToursList, getTravelTipById, clearToursList });

const selectTourDeparture = (state: GlobalState) => ({
	tourDeparturesList: state.tours.tourDeparturesList,
	tourDepartureData: state.tours.tourDepartureData,
});

export const useTourDeparture = () =>
	useRedux(selectTourDeparture, {
		getTourDeparturesList,
		getTourDepartureDataById,
		clearTourDeparturesList,
	});

const selectTourFilter = (state: GlobalState) => ({
	tourFilter: state.tours.filter,
});

export const useTourFilter = () => useRedux(selectTourFilter, { setTourFilter, clearTourFilter });
