import React, { useEffect } from 'react';
import classnames from 'classnames';

import { useTour } from 'models/tour';
import DividerSimple from 'components/atoms/DividerSimple';
import TourGuideTableItem from 'components/atoms/TourGuideTableItem';
import TourGuideTableItemOnlyTags from 'components/atoms/TourGuideTableItemOnlyTags';
import StarIcon from 'images/icon/star-large.inline.svg';

import styles from './index.module.css';

interface TourTableProperty {
	className?: string;
}

const TourGuideTable: React.FC<TourTableProperty> = ({ className }) => {
	const [{ allToursList }, { getAllToursList }] = useTour();

	// 過濾掉 isHidden 為 true 的項目
	const filteredToursList = allToursList.filter(tour => !tour.isHidden);

	const allToursCountries = filteredToursList.map(tour => ({
		id: tour.id,
		countries: tour.countries?.map(country => ({
			id: country.id,
			name: country.name,
			area: country.area,
		})),
	}));

	// 日本、亞洲、海島 選單內容
	const japanTags = [
		{ id: 1, name: '北海道' },
		{ id: 2, name: '東北' },
		{ id: 3, name: '關東' },
		{ id: 4, name: '北陸 立山黑部' },
		{ id: 5, name: '關西' },
		{ id: 6, name: '四國/山陰山陽' },
		{ id: 7, name: '九州' },
		{ id: 8, name: '客製化/半自助' },
	];

	const asiaTags = [
		{ id: 1, name: '不丹' },
		{ id: 1, name: '越南' },
		{ id: 1, name: '泰國' },
		{ id: 1, name: '馬來西亞&新加玻' },
		{ id: 1, name: '沖繩' },
		{ id: 1, name: '菲律賓' },
		{ id: 1, name: '印尼' },
		{ id: 1, name: '其他CLUB MED' },
	];

	const islandTags = [
		{ id: 1, name: '峇里島' },
		{ id: 1, name: '馬爾地夫' },
		{ id: 1, name: '蘇梅島' },
		{ id: 1, name: '帛琉' },
		{ id: 1, name: '普吉島' },
		{ id: 1, name: '大溪地' },
		{ id: 1, name: '夏威夷' },
		{ id: 1, name: '其他' },
	];

	useEffect(() => {
		getAllToursList();
	}, []);

	return (
		<div className={classnames(styles.wrapper, className)}>
			<div className={styles.tourGuideTables}>
				<div className={styles.tourGuideTable}>
					<div className={styles.thead}>
						<div className={styles.area}>
							<StarIcon />
							中西歐
						</div>
						<div className={styles.area}>
							<StarIcon />
							南歐
						</div>
						<div className={styles.area}>
							<StarIcon />
							東歐
						</div>
						<div className={styles.area}>
							<StarIcon />
							北歐
						</div>
						<div className={styles.area}>
							<StarIcon />
							非洲、中東
						</div>
					</div>
					<DividerSimple />
					<div className={styles.tbody}>
						<TourGuideTableItem
							className={styles.country}
							areas={['中歐', '西歐']}
							countryData={allToursCountries}
						/>
						<TourGuideTableItem
							className={styles.country}
							areas={['南歐']}
							countryData={allToursCountries}
						/>
						<TourGuideTableItem
							className={styles.country}
							areas={['東歐']}
							countryData={allToursCountries}
						/>
						<TourGuideTableItem
							className={styles.country}
							areas={['北歐']}
							countryData={allToursCountries}
						/>
						<TourGuideTableItem
							className={styles.country}
							areas={['非洲', '中東']}
							countryData={allToursCountries}
						/>
					</div>
				</div>
				<div className={styles.tourGuideTable}>
					<div className={styles.thead}>
						<div className={styles.area}>
							<StarIcon />
							日本
						</div>
						<div className={styles.area}>
							<StarIcon />
							亞洲
						</div>
						<div className={styles.area}>
							<StarIcon />
							海島
						</div>
						<div className={styles.area}>
							<StarIcon />
							美洲
						</div>
						<div className={styles.area}>
							<StarIcon />
							其他
						</div>
					</div>
					<DividerSimple />
					<div className={styles.tbody}>
						<TourGuideTableItemOnlyTags data={japanTags} className={styles.country} />
						<TourGuideTableItemOnlyTags data={asiaTags} className={styles.country} />
						<TourGuideTableItemOnlyTags data={islandTags} className={styles.country} />
						<TourGuideTableItem
							countryData={allToursCountries}
							className={styles.country}
							areas={['美洲']}
						/>
						<TourGuideTableItem
							countryData={allToursCountries}
							className={styles.country}
							areas={['其他']}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TourGuideTable;
